<template>
  <div class="form-parent-container">
    <div class="form-child-container">
      <div class="md:text-lg lg:text-xl xl:text-2xl font-medium text-center">
        What business tools are you using currently?
      </div>
      <div class="pt-4 sm:text-xs xl:text-sm text-gray-600 text-center">
        Select tools that help you run your business
      </div>
      <div class="pt-20">
        <label id="listbox-label" class="label">
          Search for a tool
        </label>
        <div class="relative w-full text-gray-400 focus-within:text-gray-600">
          <div
            class="absolute inset-y-0 left-0 flex items-center pointer-events-none mx-2"
          >
            <!-- Heroicon name: search -->
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z"
                stroke="#A0AEC0"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M17.5 17.4999L13.875 13.8749"
                stroke="#A0AEC0"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <input
            class="block w-full h-full pl-9 pr-3 py-3 border-transparent text-gray-900 placeholder-gray-400 hover:outline-none hover:ring-indigo-500 focus:border-indigo-500 focus:border-transparent sm:text-sm border-gray-300"
            placeholder="Type to search"
            autocomplete="off"
            type="text"
            name="search"
            v-model="search"
            @keydown.enter.prevent="addTool(search)"
          />
        </div>
      </div>
      <div class="flex flex-wrap mt-4">
        <span
          v-for="tool in selectedTools"
          :key="tool"
          class="flex border border-gray-300 rounded-full py-1 px-2 mr-3 mb-3 justify-center items-center"
        >
          <img
            :src="require(`../assets/images/${getChipImage(tool)}.svg`)"
            :alt="getLabel(tool)"
            class="h-5 w-5 rounded-full border bg-gray-50 mr-2"
          />
          <span class="text-xs capitalize">{{ getLabel(tool) || tool }}</span>
          <img
            class="ml-2 cursor-pointer"
            src="@/assets/images/x.svg"
            alt="cancel-icon"
            @click.prevent="addOrRemoveTool(tool)"
          />
        </span>
      </div>
      <div style="height: 18rem">
        <div
          v-if="filteredTools.length !== 0"
          style="overflow: auto"
          class="h-full my-4 grid grid-cols-2 sm:grid-cols-3 xl:grid-cols-4 gap-x-8 gap-y-4 items-center content-start customScrollbar"
        >
          <button
            style="height: 8rem"
            v-for="{ value, label } in filteredTools"
            :key="value"
            class="relative border py-4 px-3 shadow-sm sm:text-sm border-gray-300 rounded bg-white max-w-lg m-1 focus:outline-none"
            :class="
              selectedTools.includes(value) && [
                'ring-green-500',
                'border-green-500',
                'ring-1'
              ]
            "
            @click.prevent="addOrRemoveTool(value)"
          >
            <span
              v-if="selectedTools.includes(value)"
              class="m-1 w-5 h-5 absolute inset-y-0 right-0"
            >
              <img
                src="@/assets/images/step-completed.svg"
                alt="completed-step"
              />
            </span>
            <span class="flex justify-center">
              <img
                :src="require(`../assets/images/${value}.svg`)"
                :alt="`${label}`"
              />
            </span>
          </button>
        </div>
        <div
          v-else-if="!selectedTools.includes(search)"
          class="flex flex-col items-center"
        >
          <div class="text-2xl">
            We couldn’t find a match
          </div>
          <div
            class="mt-8 text-indigo-500 cursor-pointer"
            @click.prevent="addOrRemoveTool(search)"
          >
            Press 'enter' or click here to add.
          </div>
        </div>
      </div>
      <div class="pt-8 flex justify-end">
        <button
          class="base-filled-button gray-btn flex justify-center items-center"
          @click="
            $router.push({
              name: 'businessAddressPage'
            })
          "
        >
          <Icon icon="arrow-left" height="20" />
          <span class="ml-1">Back</span>
        </button>
        <button
          class="base-filled-button indigo-btn flex justify-center items-center"
          @click.prevent.stop="next"
        >
          <span class="mr-1">Next</span>
          <Icon icon="arrow-right" height="20" />
        </button>
      </div>
    </div>
    <modal
      :active="showModal"
      @closeModal="showModal = false"
      :background="true"
      :minWidth="'60%'"
    >
      <div class="p-6 bg-white rounded">
        <div>
          <div class="mx-auto flex items-center justify-center w-80 h-40">
            <!-- Heroicon name: check -->
            <img
              src="@/assets/images/features_overview.svg"
              alt="feature image"
            />
          </div>
          <div class="mt-3 text-center sm:mt-5 modal-text text-gray-500">
            <b class="text-black">HighLevel</b> has lot of features to
            accomplish your Marketing Automation goals including Email / SMS
            marketing, Unlimited Sales Funnels, Call tracking, Surveys and
            Forms, Appointment booking, CRM, Analytics, Reputation Management,
            Website Builder
          </div>
          <div class="text-gray-500 text-center mt-6 mb-16">
            ...and even more
          </div>
        </div>

        <div class="mt-5 sm:mt-6 flex justify-center">
          <button
            class="base-filled-button indigo-btn flex justify-center items-center disabled:cursor-default"
            @click.prevent.stop="submitTools"
            :disabled="showLoader"
          >
            <spinner v-if="showLoader" />
            <span v-else class="mr-1">Got it</span>
          </button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import { Icon, addIcon } from '@iconify/vue'
import Modal from '@/components/UI/Modal.vue'
import Spinner from '@/components/UI/Spinner.vue'
import arrowRight from '@iconify/icons-mdi/arrow-right'
import arrowLeft from '@iconify/icons-mdi/arrow-left'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
addIcon('arrow-right', arrowRight)
addIcon('arrow-left', arrowLeft)
export default defineComponent({
  components: {
    Icon,
    Modal,
    Spinner
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const tools = [
      { value: 'mailchimp', label: 'Mailchimp' },
      { value: 'clickfunnels', label: 'Click Funnels' },
      { value: 'calendly', label: 'Calendly' },
      { value: 'activecampaign', label: 'Active Campaign' },
      { value: 'pipedrive', label: 'Pipedrive' },
      { value: 'leadpages', label: 'Leadpages' },
      { value: 'wordpress', label: 'Wordpress' },
      { value: 'jotform', label: 'Jotform' },
      { value: 'typeform', label: 'Typeform' },
      { value: 'kajabi', label: 'Kajabi' },
      { value: 'teachable', label: 'Teachable' },
      { value: 'wufoo', label: 'Wufoo' },
      { value: 'calltrackingmetrics', label: 'Call Tracking Metrics' },
      { value: 'scheduleonce', label: 'Schedule Once' },
      { value: 'surveygizmo', label: 'Survey Gizmo' },
      { value: 'wix', label: 'Wix' },
      { value: 'squarespace', label: 'Square Space' },
      { value: 'agencyanalytics', label: 'Agency Analytics' },
      { value: 'followupboss', label: 'Follow Up Boss' },
      { value: 'hubspot', label: 'Hubspot' }
    ]
    const showLoader = ref(false)
    const search = ref('')
    const selectedTools = ref([...store.state.business.tools])
    const showModal = ref(false)
    const filteredTools = computed(() => {
      return tools.filter(tool => {
        return tool.value.match(search.value)
      })
    })

    const addTool = value => {
      if (!selectedTools.value.includes(value)) {
        selectedTools.value.push(value)
        search.value = ''
      }
    }

    const addOrRemoveTool = value => {
      if (selectedTools.value.includes(value)) {
        selectedTools.value = selectedTools.value.filter(tool => tool !== value)
      } else {
        selectedTools.value.push(value)
        search.value = ''
      }
    }
    const getChipImage = value => {
      for (const tool of tools) {
        if (tool.value === value) {
          return value
        }
      }
      return 'generictoolicon'
    }
    const getLabel = value => {
      for (const tool of tools) {
        if (tool.value === value) {
          return tool.label
        }
      }
    }
    const next = () => {
      showModal.value = true
      store.commit('business/updateTools', selectedTools)
      // @ts-ignore
      store?.state?.iframe?.handshake?.then(parent => {
        parent.emit('ga-event', 'onb-biztools')
      })
    }
    const submitTools = () => {
      router.push({
        name: 'businessPasswordPage'
      })
      // @ts-ignore
      store?.state?.iframe?.handshake?.then(parent => {
        parent.emit('ga-event', 'onb-tools-confirm')
      })
    }
    return {
      search,
      showLoader,
      filteredTools,
      selectedTools,
      showModal,
      addOrRemoveTool,
      addTool,
      getLabel,
      getChipImage,
      next,
      submitTools
    }
  }
})
</script>

<style scoped>
.customScrollbar::-webkit-scrollbar {
  width: 0.5em;
}
.customScrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
}
.customScrollbar::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  opacity: 50%;
  border-radius: 50px;
}
</style>
