
import { defineComponent } from 'vue'
import ProgressSideBar from '@/components/ProgressSideBar.vue'
import BusinessToolsForm from '@/components/BusinessToolsForm.vue'

export default defineComponent({
  components: {
    ProgressSideBar,
    BusinessToolsForm
  },
  setup() {
    return {}
  }
})
