<template>
  <div
    class="bg-white px-8 lg:px-12 min-w-96 font-normal text-center flex flex-col sidebar"
  >
    <div class="flex justify-center pt-16">
      <img src="@/assets/images/ghl-dark-logo.svg" alt="ghl-logo" />
    </div>
    <div class="flex flex-col pt-20 lg:text-sm xl:text-base">
      <div
        v-for="(step, index) in steps"
        :key="index"
        class="flex justify-start items-center text-sm font-medium mb-8"
      >
        <span class="w-5 h-5" v-if="activeIndex === index">
          <img
            v-if="activeIndex === 1"
            src="@/assets/images/active-2.svg"
            alt="step-2"
          />
          <img
            v-if="activeIndex === 2"
            src="@/assets/images/active-3.svg"
            alt="step-3"
          />
        </span>
        <span class="w-5 h-5" v-else-if="activeIndex < index">
          <img src="@/assets/images/inactive-3.svg" alt="step-3" />
        </span>
        <span class="w-5 h-5" v-else>
          <img src="@/assets/images/step-completed.svg" alt="completed-step" />
        </span>
        <div
          class="ml-3"
          :class="
            activeIndex === index
              ? ['text-indigo-500']
              : index > activeIndex
              ? ['text-gray-500']
              : ['text-gray-300']
          "
        >
          {{ step }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  props: {
    activeIndex: {
      type: Number,
      default: 0
    }
  },
  setup() {
    const store = useStore()
    const steps = store.state.iframe.isPasswordPending
      ? ['Business info', 'Business tools', 'Secure your account']
      : ['Business info', 'Business tools']
    return {
      steps,
      store
    }
  }
})
</script>

<style scoped>
.sidebar {
  box-shadow: 0px 0px 17px rgba(0, 0, 0, 0.11);
  min-width: 340px;
}
@media (max-width: 1024px) {
  .sidebar {
    display: none;
  }
}
</style>
