<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div v-if="active" class="fixed z-10 inset-0">
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <transition
        enter-active-class="ease-out duration-300"
        enter-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="ease-in duration-100"
        leave-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div>
          <div class="fixed inset-0 transition-opacity" aria-hidden="true">
            <div
              class="absolute inset-0"
              :class="{ 'bg-gray-500 opacity-75': background }"
            ></div>
          </div>

          <!-- This element is to trick the browser into centering the modal contents. -->
          <span
            class="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
            >&#8203;</span
          >
          <transition
            enter-active-class="ease-out duration-300"
            enter-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to-class="opacity-100 translate-y-0 sm:scale-100"
            leave-active-class="ease-in duration-200"
            leave-class="opacity-100 translate-y-0 sm:scale-100"
            leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              class="inline-block align-bottom rounded-sm text-left shadow-xl transform transition-all sm:align-middle sm:max-w-sm"
              :style="{ 'min-width': minWidth }"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
              ref="modalBodyRef"
            >
              <slot></slot>
            </div>
          </transition>
        </div>
      </transition>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onUnmounted, onMounted } from 'vue'

export default defineComponent({
  props: {
    active: {
      type: Boolean,
      require: true
    },
    closeOnBackgroundClick: {
      type: Boolean,
      default: false
    },
    background: {
      type: Boolean,
      require: true
    },
    minWidth: {
      type: String,
      require: true
    }
  },
  setup(props, { emit }) {
    const modalBodyRef = ref()

    function handler(event: MouseEvent) {
      const target = event.target as HTMLElement
      if (modalBodyRef?.value?.contains(target)) return
      if (
        !modalBodyRef?.value?.contains(target) &&
        props.closeOnBackgroundClick
      ) {
        emit('close-modal')
      }
    }

    onMounted(() => {
      window.addEventListener('click', handler)
    })

    onUnmounted(() => {
      window.removeEventListener('click', handler)
    })

    return { modalBodyRef }
  }
})
</script>

<style scoped></style>
