
import { defineComponent } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  props: {
    activeIndex: {
      type: Number,
      default: 0
    }
  },
  setup() {
    const store = useStore()
    const steps = store.state.iframe.isPasswordPending
      ? ['Business info', 'Business tools', 'Secure your account']
      : ['Business info', 'Business tools']
    return {
      steps,
      store
    }
  }
})
