<template>
  <div class="flex min-h-screen">
    <progress-side-bar :activeIndex="1" />
    <business-tools-form />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import ProgressSideBar from '@/components/ProgressSideBar.vue'
import BusinessToolsForm from '@/components/BusinessToolsForm.vue'

export default defineComponent({
  components: {
    ProgressSideBar,
    BusinessToolsForm
  },
  setup() {
    return {}
  }
})
</script>

<style scoped></style>
