
import { computed, defineComponent, ref } from 'vue'
import { Icon, addIcon } from '@iconify/vue'
import Modal from '@/components/UI/Modal.vue'
import Spinner from '@/components/UI/Spinner.vue'
import arrowRight from '@iconify/icons-mdi/arrow-right'
import arrowLeft from '@iconify/icons-mdi/arrow-left'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
addIcon('arrow-right', arrowRight)
addIcon('arrow-left', arrowLeft)
export default defineComponent({
  components: {
    Icon,
    Modal,
    Spinner
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const tools = [
      { value: 'mailchimp', label: 'Mailchimp' },
      { value: 'clickfunnels', label: 'Click Funnels' },
      { value: 'calendly', label: 'Calendly' },
      { value: 'activecampaign', label: 'Active Campaign' },
      { value: 'pipedrive', label: 'Pipedrive' },
      { value: 'leadpages', label: 'Leadpages' },
      { value: 'wordpress', label: 'Wordpress' },
      { value: 'jotform', label: 'Jotform' },
      { value: 'typeform', label: 'Typeform' },
      { value: 'kajabi', label: 'Kajabi' },
      { value: 'teachable', label: 'Teachable' },
      { value: 'wufoo', label: 'Wufoo' },
      { value: 'calltrackingmetrics', label: 'Call Tracking Metrics' },
      { value: 'scheduleonce', label: 'Schedule Once' },
      { value: 'surveygizmo', label: 'Survey Gizmo' },
      { value: 'wix', label: 'Wix' },
      { value: 'squarespace', label: 'Square Space' },
      { value: 'agencyanalytics', label: 'Agency Analytics' },
      { value: 'followupboss', label: 'Follow Up Boss' },
      { value: 'hubspot', label: 'Hubspot' }
    ]
    const showLoader = ref(false)
    const search = ref('')
    const selectedTools = ref([...store.state.business.tools])
    const showModal = ref(false)
    const filteredTools = computed(() => {
      return tools.filter(tool => {
        return tool.value.match(search.value)
      })
    })

    const addTool = value => {
      if (!selectedTools.value.includes(value)) {
        selectedTools.value.push(value)
        search.value = ''
      }
    }

    const addOrRemoveTool = value => {
      if (selectedTools.value.includes(value)) {
        selectedTools.value = selectedTools.value.filter(tool => tool !== value)
      } else {
        selectedTools.value.push(value)
        search.value = ''
      }
    }
    const getChipImage = value => {
      for (const tool of tools) {
        if (tool.value === value) {
          return value
        }
      }
      return 'generictoolicon'
    }
    const getLabel = value => {
      for (const tool of tools) {
        if (tool.value === value) {
          return tool.label
        }
      }
    }
    const next = () => {
      showModal.value = true
      store.commit('business/updateTools', selectedTools)
      // @ts-ignore
      store?.state?.iframe?.handshake?.then(parent => {
        parent.emit('ga-event', 'onb-biztools')
      })
    }
    const submitTools = () => {
      router.push({
        name: 'businessPasswordPage'
      })
      // @ts-ignore
      store?.state?.iframe?.handshake?.then(parent => {
        parent.emit('ga-event', 'onb-tools-confirm')
      })
    }
    return {
      search,
      showLoader,
      filteredTools,
      selectedTools,
      showModal,
      addOrRemoveTool,
      addTool,
      getLabel,
      getChipImage,
      next,
      submitTools
    }
  }
})
